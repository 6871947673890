/* src/styles/CustomCalendar.css */

.sb-admin-event {
    font-size: 30px; /* Smaller font size */
    height: 50px; /* Set a fixed height for events */
    line-height: 25px; /* Center text vertically */
    padding: 1px 4px; /* Smaller padding */
    margin: 1px 0; /* Adjust margin for spacing */
  }
  
  .rbc-event-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  